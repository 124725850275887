exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-campanas-tsx": () => import("./../../../src/pages/campanas.tsx" /* webpackChunkName: "component---src-pages-campanas-tsx" */),
  "component---src-pages-fees-tsx": () => import("./../../../src/pages/fees.tsx" /* webpackChunkName: "component---src-pages-fees-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payroll-tsx": () => import("./../../../src/pages/payroll.tsx" /* webpackChunkName: "component---src-pages-payroll-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-referrals-tsx": () => import("./../../../src/pages/referrals.tsx" /* webpackChunkName: "component---src-pages-referrals-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

